@use "sass:map";
@use "sass:math";

// ブレークポイントの配列
$breakpoint-list: (
    "xxs": 0,
    "xs": 480,
    "sm": 576,
    "md": 768,
    "lg": 992,
    "xl": 1200,
    "xxl": 1400,
);

// ブレークポイントの数値を取得
@function breakpoint($breakpoint) {
    @return map.get($breakpoint-list, $breakpoint);
}

// メディアクエリ
@mixin query($breakpoint: md) {
    $query: "screen and (min-width: " + breakpoint($breakpoint) + "px)";

    @media #{$query} {
        @content;
    }
}

// ブレークポイントを基準にピクセルを vw に変換して出力
@function vw($breakpoint, $value, $sign:1) {
    @return $sign * math.div($value, breakpoint($breakpoint)) * 100 + vw;
}

// 各ブレークポイントの vw 変換
@function xs($value, $sign:1) {
    @return vw(sm, $value, $sign);
}

@function sm($value, $sign:1) {
    @return vw(md, $value, $sign);
}

@function md($value, $sign:1) {
    @return vw(lg, $value, $sign);
}

@function lg($value, $sign:1) {
    @return vw(xl, $value, $sign);
}

// 単純にピクセルで出力
@function px($value, $sign:1) {
    @return $sign * $value + px;
}
