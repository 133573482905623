@use "./base/media";

body {
    font-family: 'Noto Sans JP', sans-serif;
    margin: 0;
    padding: 0;
    height: 100vh;
    display: grid;
    grid-template-rows: 100px 1fr 1fr;

    @include media.query(md) {
        grid-template-rows: 100px 1fr 12rem;
    }
}

.Page {
    &__Header {
        padding: .5rem;
    }

    &__Main {
        width: 90%;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        @include media.query(md) {
            flex-direction: row;
        }
    }

    &__MainVisual {
        text-align: center;
        margin-bottom: 1rem;
    }

    &__Footer {
        position: sticky;
        top: 100vh;
        top: 100dvh;
    }
}

.Title {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    text-align: center;
    width: 150px;
    font-size: 10px;
    margin: 0;
    font-weight: bold;

    &__Text {
        background-color: black;
        color: white;
        font-size: 13px;
    }

    &__Logo {
        margin: 0;
    }

    &__Image {
        width: 100%;
    }
}

.MainPane {
    margin: 0 2rem 5rem;

    &__Title {
        margin: 0;
    }

    &__Text {
        margin: 1rem 0;
        line-height: 2;
    }

    &__Info {
        border: solid 1px gray;
        width: 20rem;
        list-style: none;
        margin: 2rem auto;
        padding: .5rem;
    }
}

.Footer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: calc(100% - 1.5rem);
    padding: 1.5rem 3rem 0;
    background-color: #188dc8;
    text-align: center;
    color: white;

    &__LogoContainer {
        grid-column: 1 / 3;

        @include media.query(md) {
            grid-column: 1 / 2;
        }
    }

    &__Address {
        grid-column: 1 / 3;
        width: 100%;
        text-align: left;
        margin: 1rem auto;
        font-style: normal;

        @include media.query(md) {
            grid-column: 2 / 3;
        }
    }

    &__Copyright {
        margin-bottom: .5rem;
        grid-column: 1 / 3;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.FooterLogo {
    margin: 0;
    font-size: 100%;
    font-weight: 400;
    display: flex;
    flex-direction: column;
    gap: .5rem;

    &__Text {
        display: block;
        border: solid 1px white;
        text-align: center;
        width: 10rem;
        margin: auto;
    }

    &__Logo {
        margin: 0;
    }
}
