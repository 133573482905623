body {
  font-family: "Noto Sans JP", sans-serif;
  margin: 0;
  padding: 0;
  height: 100vh;
  display: grid;
  grid-template-rows: 100px 1fr 1fr;
}
@media screen and (min-width: 768px) {
  body {
    grid-template-rows: 100px 1fr 12rem;
  }
}

.Page__Header {
  padding: 0.5rem;
}
.Page__Main {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .Page__Main {
    flex-direction: row;
  }
}
.Page__MainVisual {
  text-align: center;
  margin-bottom: 1rem;
}
.Page__Footer {
  position: sticky;
  top: 100vh;
  top: 100dvh;
}

.Title {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  text-align: center;
  width: 150px;
  font-size: 10px;
  margin: 0;
  font-weight: bold;
}
.Title__Text {
  background-color: black;
  color: white;
  font-size: 13px;
}
.Title__Logo {
  margin: 0;
}
.Title__Image {
  width: 100%;
}

.MainPane {
  margin: 0 2rem 5rem;
}
.MainPane__Title {
  margin: 0;
}
.MainPane__Text {
  margin: 1rem 0;
  line-height: 2;
}
.MainPane__Info {
  border: solid 1px gray;
  width: 20rem;
  list-style: none;
  margin: 2rem auto;
  padding: 0.5rem;
}

.Footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: calc(100% - 1.5rem);
  padding: 1.5rem 3rem 0;
  background-color: #188dc8;
  text-align: center;
  color: white;
}
.Footer__LogoContainer {
  grid-column: 1/3;
}
@media screen and (min-width: 768px) {
  .Footer__LogoContainer {
    grid-column: 1/2;
  }
}
.Footer__Address {
  grid-column: 1/3;
  width: 100%;
  text-align: left;
  margin: 1rem auto;
  font-style: normal;
}
@media screen and (min-width: 768px) {
  .Footer__Address {
    grid-column: 2/3;
  }
}
.Footer__Copyright {
  margin-bottom: 0.5rem;
  grid-column: 1/3;
  display: flex;
  align-items: center;
  justify-content: center;
}

.FooterLogo {
  margin: 0;
  font-size: 100%;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
.FooterLogo__Text {
  display: block;
  border: solid 1px white;
  text-align: center;
  width: 10rem;
  margin: auto;
}
.FooterLogo__Logo {
  margin: 0;
}